import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ScrollToTop from 'react-scroll-to-top'
// import ScrollToTop from 'react-scroll-up'

// import FullCard from './fullCard/FullCard'
// import Rectangle from './../image/Rectangle 18.png'
import Clock from './../image/Clock (1).png'
import Eye from './../image/Eye.png'
import su from './../image/photo_2021-08-02_17-58-35.jpg'
import kunj from './../image/kunj.jpg'
import smetana from './../image/9c7820137dc20ece.jpg'
import yogurt from './../image/7709335-3.jpg'
import kefir from './../image/photo_2021-08-02_17-58-54.jpg'
import sir from './../image/photo_2021-08-02_17-59-03.jpg'
import qaymoq from './../image/photo_2021-08-02_17-58-44.jpg'
// import goUp from '../image/go up.png'
import GoUp from '../../svg/Go up'

// import FunnelSimple from './../image/FunnelSimple.png'
import './News.scss'

const News = () => {
  const [scrollToTop, setScrollToTop] = useState(false)

  window.addEventListener('scroll', function () {
    console.log(window.scrollY)
    if (window.scrollY >= 1402) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)
    }
  })
  // const [filtr, setfiltr] = useState(true)
  // console.log(filtr)
  // const byDateChange = () => {
  //   setfiltr(true)
  //   console.log(filtr)
  // }
  const arr = [
    {
      id: '1',
      image: su,
      sana: ' 17:52 / 21.07.2021',
      kurilganar: '22271',
      text: `Nordon bo‘lib qolgan sutni quymoq yoki oshirma xamir uchun ishlatsangiz bo‘ladi. Bu tabiyatning tengi yo’q in’omi bo’lib, afsuski bizning vaqtimizda (hozirgi kunda) sutlarning ko’p turi konsentrant – kukundan tayyorlangan. Sportsmenlarga sutni iste’mol qilish, uni yog’siz yoki yog’ foizi kam bo’lganini tanlash, ma’lum bir protein kokteyiliga qo’shib, uni yanada mazzali qilib tayyorlash imkonini beradi. Mahsulotning yog’lilik darajini bilgan holda, atlet tanasida ortiqcha yog’ to’planishidan qutiladi, ahir sut yog’lari juda ham to’yingan yog’dir. Sutning salbiy tomoni esa, bu uning tabiiy holdagisida foydali vitamin va minerallarning yo’qligi, faqatgina ishlab chiqaruvchi tomonidan qo’shilmagan bo’lsa, bu ham ko’p hollarda yozilgan me’yoridan kamroq bo’lishidir`,
      theme:
        'Sut mahsulotlari nima uchun ayniydi va bunday vaziyatda nima qilish kerak?',
    },
    {
      id: '2',
      image: kunj,
      sana: ' 09:22 / 13.01.2021',
      kurilganar: '40614',
      text: `Sedana yog‘idan nafaqat taom tayyorlashda, balki kosmetologiyada ham samarali foydalanish mumkin`,
      theme:
        'Qora sedananing foydali va shifobaxsh xususiyatlari: Uni qanday iste’mol qilish kerak?',
    },
    {
      id: '3',
      image: smetana,
      sana: ' 15:50 / 13.05.2020',
      kurilganar: '29520',
      text: `Bugungi kunda ushbu mahsulot sanoat sharoitida ishlab chiqarilmoqda. Va bir vaqtlar stolda uy qurilishi smetana mavjud edi. Qanday qilib biz o'z qo'limiz bilan tatib ko'rganiga ishonchimiz komil bo'lsa ham, bunga shubha qilishimiz mumkin. `,
      theme:
        'Sut Mahsulotlari - Smetana. Tarkibi, Foydalari, Ishlab Chiqaruvchilari',
    },
    {
      id: '4',
      image: yogurt,
      sana: ' 15:50 / 17.06.2021',
      kurilganar: '2210',
      text: `Yogurtning foydasi uni tayyorlash uchun ishlatiladigan sutning foydali xususiyatlari bilan belgilanadi. Ushbu mahsulot tirik bakteriyalarning o'ziga xos xususiyatlariga ega. Bifido-ilaktobakteriyalar ko'plab kasalliklarning sababi bo'lgan zararli bakteriyalarni ko'payishini oldini olishga qodir. Ular ichak mikroflorasini normallashtiradi, nitritlarning zararli ta'sirini zararsizlantiradi va zamburug'larni bostiradi. Shu tufayli jonli yogurt dysbioz va oshqozon-ichak trakti muammolariga qarshi kurashda yordamchiga aylanadi. Bu kandidoz va yo'g'on ichak saratonining oldini olishga xizmat qiladi.`,
      theme: 'Yogurt nima uchun foydali?',
    },
    {
      id: '5',
      image: kefir,
      sana: ' 15:50 / 17.06.2021',
      kurilganar: '2210',
      text: `Kefir Rossiyaga Elbrus tog'larining etaklaridan kelgan. Kavkazda birinchi marta xamirturush yaratildi, uning retsepti hanuzgacha sir saqlanib kelinmoqda. Kavkazda dam olishga kelgan mehmonlar tetiklantiruvchi ichimlikni tatib ko'rgach, shifokorlar kefirning kimyoviy tarkibini o'rgangach, ichimlik Rossiyada tarqatila boshlandi.`,
      theme: 'Kefir - Foydasi, Zarari Va Ichimlik Tanlash Qoidalari',
    },
    {
      id: '6',
      image: sir,
      sana: ' 19:50 / 12.04.2021',
      kurilganar: '35885',
      text: `292 mingta quyosh paneli. 268 gektar yer maydoni. 600dan ortiq
        inverter. 1000 km.dan ortiq turli kabellar. Bu – O‘zbekistondagi
        ilk «Navoiy Nur» quyosh elektr stansiyasining texnik tavsiflari.
        110 mln dollarlik obekt reja bo‘yicha shu yilning 3-choragida
        foydalanishga topshiriladi. Kun.uz muxbiri loyiha ustidagi ishlar
        qanday ketayotgani bilan tanishish `,
      theme: 'Pishloqning foyda va zarari haqida',
    },
    {
      id: '7',
      image: qaymoq,
      sana: ' 15:50 / 17.06.2021',
      kurilganar: '2210',
      text: `O‘zbekistonda sutdan turli mahsulotlar tayyorlash madaniyati 
        asrlar davomida shakllanib kelgan. Bu yerning o‘zgacha iqlimi va geografik 
        sharoiti tufayli sog‘ib olinadigan suti ham boshqacha. Mahalliy sigirlar 
        kamsut ekanini ko‘pchilik biladi. Ulardan sog‘ib olinadigan sut unchalik 
        ko‘p emas, kuniga 7—11 litrni tashkil etadi.  Vaholanki, bu boradagi 
        o‘rtacha statistik raqamlar 20—25 litr, Yevropa zotdor sigirlari uchun esa 
        35—40 litr sutdan iborat. Ammo bu qanday sut? Dunyodagi eng mazali sut. 
        Nima uchun? Chunki uning yog‘lilik darajasi juda yuqori — 4,5 foiz. Taqqoslash 
        uchun, belgilangan o‘rtacha yog‘lilik darajasi 3,2—3,7 foizni tashkil etadi....`,
      theme: 'Bir piyola qaymoq bilan qaynoqqina shirmoy non',
    },
  ]
  const history = useHistory()

  // window.addEventListener('scroll', function () {
  //   // console.log(window.scrollY)
  //   if (window.scrollY >= 1419) {
  //     document.querySelector('.scrolltotop').style.position = 'absolute'
  //     document.querySelector('.scrolltotop').style.bottom = '50px'
  //     // document.querySelector('.scrolltotop').style.right = '30px'
  //   } else {
  //     document.querySelector('.scrolltotop').style.bottom = '50px'
  //     // document.querySelector('.scrolltotop').style.right = '30px'
  //     document.querySelector('.scrolltotop').style.position = 'fixed'
  //   }
  // })
  // console.log(history)
  return (
    <div className="news">
      <div className="wrapper">
        <div className="datenews">
          <h1>YANGILIKLAR</h1>
          {/* <div
            className="dateIncrease"
            onClick={() => {
              filtr ? setfiltr(false) : setfiltr(true)
              // console.log(filtr)
            }}
          >
            <img src={FunnelSimple} alt="FunnelSimple" />
            <p className="filtr">Filtr</p>
            <div
              className="increaseDecrase"
              style={filtr ? { display: 'none' } : { display: 'flex' }}
            >
              <p>Sana bo'yicha o'sish</p>
              <p>Sana bo'yicha kamayish</p>
            </div>
          </div> */}
        </div>
        <div className="newsContainer">
          {arr.map((item, index) => (
            <div
              onClick={() => history.push(`/yangiliklar/${item.id}`)}
              className="first"
              key={index}
            >
              <div>
                <img
                  className="news_img"
                  src={item.image}
                  alt="Rectangle"
                  height="250px"
                />
              </div>
              <div className="reference">
                <div className="time">
                  <p className="clock">
                    <span>
                      <img src={Clock} alt="Clock" width="12px" />
                    </span>
                    {item.sana}
                  </p>
                  <p className="eye">
                    <span>
                      <img src={Eye} width="12px" alt="eye" />
                    </span>
                    {item.kurilganar}
                  </p>
                </div>
                <h3>{item.theme}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <button
        id="buttonToTop"
        easing="linear"
        className="scrolltotop"
        onClick={() => window.scrollTo(0, 0)}
        // onClick={buttonScroll}
        style={{
          position: 'fixed',
          cursor: 'pointer',
          bottom: '50px',
          right: '30px',
          boxShadow: '0 0 10px 3px white',
          zIndex: '15',
          borderRadius: '50%',
        }}
      >
        <GoUp />
      </button> */}
      <ScrollToTop smooth />
    </div>
  )
}

export default News
