import React, { useState } from 'react'
import Facebook from '../../svg/Facebook'
import Instagram from '../../svg/Instagram'
import Telegram from '../../svg/Telegram'

import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component'

import './Contact.scss'

const Contact = () => {
  const nameErr = React.createRef()
  const numberErr = React.createRef()
  const textErr = React.createRef()
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const messageChange = (e) => {
    setMessage(e.target.value)
  }
  const nameChange = (e) => {
    setName(e.target.value)
  }
  const numberChange = (e) => {
    setNumber(e.target.value)
  }

  const sendingMessage = () => {
    if (message && name && number) {
      store.addNotification({
        title: '',
        message: 'Xabaringiz yuborildi!',
        type: 'success', // 'default', 'success', 'info', 'warning'
        container: 'top-right', // where to position the notifications
        animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
        animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
        dismiss: {
          duration: 2000,
        },
      })
      document.getElementById('message').value = ''
      setMessage('')
      document.getElementById('name').value = ''
      setName('')
      document.getElementById('number').value = ''
      setNumber('')
      nameErr.current.classList.remove('error')
      numberErr.current.classList.remove('error')
      textErr.current.classList.remove('error')
    } else {
      if (!name.length) {
        nameErr.current.classList.add('error')
      } else {
        nameErr.current.classList.remove('error')
      }
      if (!number.length) {
        numberErr.current.classList.add('error')
      } else {
        numberErr.current.classList.remove('error')
      }
      if (!message.length) {
        textErr.current.classList.add('error')
      } else {
        textErr.current.classList.remove('error')
      }
    }
  }

  return (
    <div className="contact" id="bog'lanish">
      <div className="contactContainer">
        <h1>BOG'LANISH</h1>
        <div className="contactSections">
          <div className="contactSectionsSection">
            <h3>SAVDO BO'LIMI</h3>
            <h2>93 622 74 52</h2>
          </div>

          <div className="contactSectionsSection">
            <h3>MARKETING VA REKLAMA BO'LIMI</h3>
            <h2>91 991 99 88</h2>
          </div>

          <div className="contactSectionsSection">
            <h3>SIFAT NAZORATI BO'LIMI</h3>
            <h2>90 619 39 19</h2>
          </div>
        </div>
        <h5>BIZ BILAN BOG'LANISH</h5>
        <div className="contactUsers">
          <div className="contactUsersName">
            <div className="contactUsersNameFirstName">
              <input
                ref={nameErr}
                type="text"
                placeholder="Ismingiz"
                id="name"
                onChange={nameChange}
              />
              {/* <span>Ismingiz</span> */}
            </div>

            <div className="contactUsersNameNumber">
              <input
                ref={numberErr}
                type="number"
                placeholder="Telefon raqamingiz"
                id="number"
                onChange={numberChange}
              />
            </div>
          </div>
          <div className="contactUsersTextarea">
            <textarea
              ref={textErr}
              id="message"
              placeholder="Xabaringizni qoldiring"
              onChange={messageChange}
            />
            <button>
              <p onClick={sendingMessage}>Yuborish</p>
            </button>
          </div>
        </div>
        <div className="contactMA">
          <div className="contactMAMessengers">
            <p>Biz ijtimoiy tarmoqlarda:</p>
            <div>
              <Instagram />
              <Facebook />
              <Telegram />
            </div>
          </div>
          <p>
            Manzil:
            <span>Navoi shahri S.Ayniy ko‘chasi, 52A .</span>
          </p>
        </div>
        <div>
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A1fa6197d8b880b2c0eaec02467498e0481e05c9211e404b251499373cc1391d0&amp;source=constructor"
            width="100%"
            height="339px"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact
