import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import ScrollToTop from 'react-scroll-to-top'
import { ScrollTrigger, Power1 } from 'gsap/all'
import './ProductComponent.scss'
import kifir from './../image/kifir.png'
import smetana from './../image/smetana.png'
import banana from './../image/banan 2.png'
import smetanaOne from './../image/plastic-yogurt-cup-1122 1.png'
import bananYogurt from './../image/banan.png'
import qyulupYogurt from './../image/qyulup.png'
import GoUp from '../../svg/Go up'

gsap.registerPlugin(ScrollTrigger)

const anim = [
  {
    id: 1,
    mahsulotlar: kifir,
    banana: banana,
  },
  {
    id: 2,
    mahsulotlar: smetana,
    banana: banana,
  },
  {
    id: 2,
    mahsulotlar: smetanaOne,
    banana: banana,
  },
  {
    id: 2,
    mahsulotlar: bananYogurt,
    banana: banana,
  },
  {
    id: 2,
    mahsulotlar: qyulupYogurt,
    banana: banana,
  },
]

const ProductComponent = () => {
  const products = useRef([])
  products.current = []
  const productsRef = (el) => {
    if (el && !products.current.includes(el)) {
      products.current.push(el)
    }
  }

  const rectangle = useRef([])
  rectangle.current = []
  const addToRef = (el) => {
    if (el && !rectangle.current.includes(el)) {
      rectangle.current.push(el)
    }
    // console.log(rectangle.current)
  }

  console.log(window.scrollY)
  useEffect(() => {
    rectangle.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          // x: 400,
          // duration: 1.5,
          ease: 'power1',
          // delay: 1,
          scrollTrigger: {
            id: 'section-$(index+1)',
            trigger: el,
            markers: false,
            start: 'top center+=190 ',
            // end: 'top ',
            toggleActions: 'play none none reverse',
            // scrub: true,
          },
        }
      )
    })

    products.current.forEach((el) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: -100,
          transform: 'rotate(0deg)',
          duration: 0.5,
          ease: Power1.easeOut,
          delay: 0.5,
          scrollTrigger: {
            id: 'section-$(index+2)',
            trigger: el,
            markers: false,
            start: 'top center+=150 ',
            // end: 'top ',
            toggleActions: 'play none none reverse',
            // scrub: true,
          },
        }
      )
    })
  }, [])

  // window.addEventListener('scroll', function () {
  //   // console.log(window.scrollY)
  //   if (window.scrollY >= 1611) {
  //     document.querySelector('.scrolltotop').style.position = 'absolute'
  //     document.querySelector('.scrolltotop').style.bottom = '50px'
  //     // document.querySelector('.scrolltotop').style.right = '30px'
  //   } else {
  //     document.querySelector('.scrolltotop').style.bottom = '50px'
  //     // document.querySelector('.scrolltotop').style.right = '30px'
  //     document.querySelector('.scrolltotop').style.position = 'fixed'
  //   }
  // })

  return (
    <div className="productComponent" id="2">
      <div className="productContainer">
        <h1>MAHSULOTLAR</h1>

        {anim.map((item, index) => {
          return (
            <div className="oneProdact">
              <div key={item} className="oneProdactIn" ref={addToRef}></div>
              <img
                src={item.mahsulotlar}
                alt="kifir"
                ref={productsRef}
                className="productImage"
              />
              <div className="productAbout">
                <h4>NOMI</h4>
                <h3>OLCHALI YOGURT</h3>
                <p>Yog' tarkibi:3.5%</p>
                <p>Nominal hajmi:330gr</p>
                <p>Yaroqlilik muddati: 7 kun</p>
                <p>Energetik quvvati: 103kkal</p>
              </div>
            </div>
          )
        })}

        {anim.map((item, index) => {
          return (
            <div className="oneProdactResponsive">
              <div className="circle"></div>
              <div className="prodactAll">
                <img src={item.mahsulotlar} alt="mahsulot" className="mahsulot" />
                <div className="productAbout">
                  <h4>NOMI</h4>
                  <h3>OLCHALI YOGURT</h3>
                  <p>Yog' tarkibi:3.5%</p>
                  <p>Nominal hajmi:330gr</p>
                  <p>Yaroqlilik muddati: 7 kun</p>
                  <p>Energetik quvvati: 103kkal</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* <button
        id="buttonToTop"
        easing="linear"
        className="scrolltotop"
        onClick={() => window.scrollTo(0, 0)}
        // onClick={buttonScroll}
        style={{
          position: 'fixed',
          bottom: '50px',
          right: '40px',
          boxShadow: '0 0 10px 3px white',
          cursor: 'pointer',
          borderRadius: '50%',
          zIndex: '15',
        }}
        showUnder={600}
        duration={200}
      >
        <GoUp />
      </button>
      
      */}
      <ScrollToTop smooth />
    </div>
  )
}

export default ProductComponent
