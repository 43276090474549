import React from 'react'

function ArrowCircleLeft() {
  return (
    <div>
      <svg
        width="41.25"
        height="41.25"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.5 48.125C38.8909 48.125 48.125 38.8909 48.125 27.5C48.125 16.1091 38.8909 6.875 27.5 6.875C16.1091 6.875 6.875 16.1091 6.875 27.5C6.875 38.8909 16.1091 48.125 27.5 48.125Z"
          stroke="#6D35A2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.1983 34.792L18.9062 27.5L26.1983 20.208"
          stroke="#6D35A2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.9062 27.5H36.0938"
          stroke="#6D35A2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

export default ArrowCircleLeft
