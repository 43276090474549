import React, { useState } from 'react'
import './Shoping.scss'
import location from './../image/Group.png'
import wave from './../image/Vector 9.png'

const Shoping = () => {
  const [count, setCount] = useState(false)
  const closingCard = () => {
    if (count) {
      setCount(false)
    } else {
      console.log('working!!!')
    }
  }
  return (
    <div onClick={closingCard} className="shoping" id="xarid-qilish">
      <div className="shopingContainer">
        <h1>XARID QILISH</h1>
        <div className="shopingMiniCircle"></div>
        <div className="shopingBigCircle">
          <img className="shopingWave" src={wave} alt="wave" />
          <img className="shopingWave1" src={wave} alt="wave" />
        </div>
        <img className="shopingWave2" src={wave} alt="wave" />
      </div>
      <div className="shopingMap">
        <div className="shopingMapContainer">
          <p>Navoiy</p>
          <div className="shoppingMapLocation">
            <img
              src={location}
              onClick={() => setCount(true)}
              className="shopingMapLocationImage"
              alt="location"
            />
            <div
              style={count ? { display: 'block' } : { display: 'none' }}
              className="shopingMapDiller"
            >
              <h3>Diller</h3>
              <h2>Orifjon Savriyev</h2>
              <h4>+998 91 991 99 88</h4>
              <span className="shopingMapDillerSpan"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shoping
