import React from 'react'
// import { useWindowScroll } from 'react-use'
import './Footer.scss'

import softex from './../image/softex.png'

// const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: 'smooth' })
// }
const Footer = () => {
  return (
    <div className="footer">
      <p className="footer_2021">&copy; 2021 Shifobaxsh</p>
      <div className="footer_softex">
        <p>Sayt yaratuvchi</p>
        <a href="https://softex.uz/">
          <img src={softex} alt="softex" />
        </a>
        {/* <button>tepaga</button> */}
      </div>
    </div>
  )
}

export default Footer
