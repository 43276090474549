import React from 'react'

const Instagram = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8492 6.15108C31.8831 2.18458 26.6092 0 21 0C15.3908 0 10.1177 2.18458 6.15108 6.15108C2.18458 10.1177 0 15.3908 0 21C0 26.61 2.18458 31.8834 6.15108 35.8496C10.1173 39.8158 15.3908 42 21 42C26.6096 42 31.8831 39.8158 35.8492 35.8496C39.8154 31.8834 42 26.61 42 21C42 15.3908 39.8154 10.1173 35.8492 6.15108ZM34.9212 34.9216C31.203 38.6398 26.2591 40.6874 21 40.6874C15.7413 40.6874 10.7974 38.6398 7.07916 34.9216C3.3605 31.2034 1.3126 26.2591 1.3126 21C1.3126 15.7417 3.3605 10.7978 7.07916 7.07916C10.7978 3.3605 15.7417 1.3126 21 1.3126C26.2587 1.3126 31.2026 3.3605 34.9212 7.07916C38.6394 10.7974 40.6874 15.7413 40.6874 21C40.6874 26.2595 38.6394 31.2034 34.9212 34.9216Z"
        fill="#4D1384"
      />
      <path
        d="M35.2766 9.4315C35.0483 9.14983 34.6349 9.10666 34.3533 9.33532C34.0717 9.56367 34.0289 9.977 34.2572 10.2582C36.711 13.2826 38.0623 17.0974 38.0623 21.0003C38.0623 25.5585 36.2876 29.8434 33.0652 33.0658C29.8428 36.2882 25.5578 38.0625 21.0001 38.0625C16.7952 38.0625 12.7554 36.52 9.62551 33.7187C9.35511 33.4768 8.94055 33.5 8.69866 33.77C8.45718 34.0399 8.47995 34.4549 8.74993 34.6964C12.1213 37.7137 16.4716 39.3751 21.0001 39.3751C25.9083 39.3751 30.523 37.4641 33.9933 33.9939C37.4635 30.5236 39.3749 25.9089 39.3749 21.0003C39.3749 16.7974 37.9194 12.689 35.2766 9.4315Z"
        fill="#4D1384"
      />
      <path
        d="M3.93721 20.9998C3.93721 11.5915 11.5916 3.93708 20.9999 3.93708C25.2048 3.93708 29.2442 5.47998 32.3744 8.28144C32.6444 8.52333 33.0594 8.50005 33.301 8.23017C33.5429 7.96018 33.5196 7.54521 33.2497 7.30363C29.8787 4.28643 25.528 2.625 20.9999 2.625C16.0921 2.625 11.4779 4.53632 8.00717 8.00704C4.53644 11.4777 2.62512 16.0919 2.62512 20.9998C2.62512 25.2031 4.08066 29.3118 6.72338 32.569C6.8532 32.7292 7.04228 32.8121 7.23331 32.8121C7.37871 32.8121 7.52493 32.7641 7.64664 32.6651C7.9278 32.4369 7.97107 32.0235 7.74282 31.7423C5.28866 28.7178 3.93721 24.9026 3.93721 20.9998Z"
        fill="#4D1384"
      />
      <path
        d="M26.5155 10.4717H15.5254C12.7388 10.4717 10.4718 12.7388 10.4718 15.5254V26.5154C10.4718 29.3021 12.7388 31.5691 15.5254 31.5691H26.5155C29.3021 31.5691 31.569 29.3021 31.569 26.5154V15.5254C31.569 12.7388 29.3021 10.4717 26.5155 10.4717ZM30.2385 26.5153C30.2385 28.5681 28.5683 30.2383 26.5155 30.2383H15.5254C13.4727 30.2383 11.8028 28.5681 11.8028 26.5153V15.5254C11.8028 13.4727 13.4728 11.8027 15.5254 11.8027H26.5155C28.5683 11.8027 30.2385 13.4728 30.2385 15.5254V26.5153Z"
        fill="#4D1384"
      />
      <path
        d="M26.9168 14.3276C26.4699 14.3276 26.1063 14.6911 26.1063 15.1378C26.1063 15.5845 26.4699 15.9479 26.9168 15.9479C27.3634 15.9479 27.7268 15.5845 27.7268 15.1378C27.7268 14.6911 27.3634 14.3276 26.9168 14.3276Z"
        fill="#4D1384"
      />
      <path
        d="M21.0202 15.8491C18.169 15.8491 15.8494 18.1687 15.8494 21.0199C15.8494 23.8713 18.169 26.1912 21.0202 26.1912C23.8716 26.1912 26.1914 23.8713 26.1914 21.0199C26.1914 18.1687 23.8716 15.8491 21.0202 15.8491ZM21.0202 24.8606C18.9026 24.8606 17.1798 23.1377 17.1798 21.0199C17.1798 18.9027 18.9026 17.1803 21.0202 17.1803C23.1376 17.1803 24.8601 18.9027 24.8601 21.0199C24.8602 23.1377 23.1376 24.8606 21.0202 24.8606Z"
        fill="#4D1384"
      />
    </svg>
  )
}

export default Instagram
