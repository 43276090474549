import React from 'react'
import './CompanyAbout.scss'

const CompanyAbout = () => {
  return (
    <div className="company" id="kompaniya-haqida">
      <div className="companyContainer">
        <h1>KOMPANIYA HAQIDA</h1>
        <div className="text">
          <p>
            “Shifobaxsh” kompaniyasining asosiy faoliyat turi - sut mahsulotlarini
            ishlab chiqarish hisoblanadi. Kompaniya 2009 yilda tashkil topgan bo‘lib,
            shu yilning 1 oktyabr' sanasida “Shifobaxsh” brendi ostida ilk
            mahsulotini ishlab chiqargan.
          </p>
          <p>
            Dastavval 60 litr sut qayta ishlanib, 2 turdagi sut mahsulotlari ishlab
            chiqarilgan bo‘lsa, bugunga kelib kunlik 2,5–3 tonna sut qayta ishlanadi
            va xalqimiz dasturxoniga 30dan ortiq mahsulot turi yetkazib beriladi.
          </p>
          <p>
            {' '}
            Kompaniya tashkil topgan ilk yillarda 5 kishi faoliyat yuritgan bo‘lsa,
            hozirgi kunda 40 dan ziyod xodimlar xizmat qilishadi. Bu esa faoliyat
            turini kengaytirib, zamonaviy texnologiyalarni joriy etishga yordam
            beradi.
          </p>
          <p>
            {' '}
            “Shifobaxsh” sut mahsulotlari allaqachon xalqimizning sevimli tanloviga
            aylanib ulgurdi. Sababi “Shifobaxsh” brendi ostida ishlab chiqarilayotgan
            sut mahsulotlarini nafaqat viloyatimiz, balki yurtimizning ko‘plab
            hududlardagi savdo do‘konlarida uchratish mumkin.
          </p>

          <h2>Mahsulotlarimizning afzalligi:</h2>
          <ul>
            <li>
              Sog‘lik uchun zararli bo‘lgan, kimyoviy qo‘shimchalardan holi 100 %
              toza sut mahsulotlarini taqdim etamiz.
            </li>
          </ul>
          <h2>Erishgan yutuqlarimiz:</h2>
          <ul>
            <li>
              Xalqaro toifadagi “Eng toza kimyoviy ingredientlardan foydalangan
              kompaniya” nominasiyasi g‘olibi
            </li>
            <li>
              “Sog‘lom turmush tarzini namoyon qiluvchi mahsulot egasi” unvoni bilan
              taqdirlangan (Rossiya Federatsiyasi, Moskva shahri, 2012 yil)
            </li>
            <li>
              “EURO milk” kompaniyalari bilan hamkorlik qiluvchi hamda tajriba
              almashish shartnomasiga ega O‘zbekistondagi yagona kompaniya.
            </li>
          </ul>
        </div>

        <div className="company_static1">
          <div className="staticTop">
            <div className="company_static">
              <h2>Ishchilar soni</h2>
              <h3>70+</h3>
            </div>

            <div className="company_static">
              <h2>Qoramollar soni</h2>
              <h3 className="company_static_number">500+</h3>
            </div>
          </div>

          <div className="staticBottom">
            <div className="company_static">
              <h2>Sut mahsulotlarini turi</h2>
              <h3 className="company_static_number">20+</h3>
            </div>

            <div className="company_static">
              <h2>Sutni qayta ishlash (1sutka) tonna</h2>
              <h3 className="company_static_number">20+</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyAbout
