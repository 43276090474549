import React from 'react'

const Telegram = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8495 6.15103C31.8831 2.18449 26.6094 0 21 0C15.391 0 10.1176 2.18449 6.15103 6.15103C2.18449 10.1176 0 15.391 0 21C0 26.61 2.18449 31.8837 6.15103 35.85C10.1173 39.8159 15.3908 42 21 42C26.6097 42 31.8833 39.8159 35.8494 35.85C39.8157 31.8838 42 26.61 42 21C42 15.3909 39.8157 10.1174 35.8495 6.15103ZM34.9214 34.9219C31.2031 38.6399 26.2591 40.6875 21 40.6875C15.7413 40.6875 10.7974 38.6399 7.07905 34.9219C3.36041 31.2035 1.3125 26.2593 1.3125 21C1.3125 15.7416 3.36041 10.7978 7.07905 7.07905C10.7978 3.36041 15.7416 1.3125 21 1.3125C26.2589 1.3125 31.2029 3.36041 34.9214 7.07905C38.6398 10.7976 40.6875 15.7415 40.6875 21C40.6875 26.2594 38.6398 31.2036 34.9214 34.9219Z"
        fill="#4D1384"
      />
      <path
        d="M35.2766 9.43134C35.0482 9.14981 34.635 9.10675 34.3535 9.3352C34.0721 9.56358 34.029 9.97685 34.2574 10.2582C36.7112 13.2827 38.0625 17.0977 38.0625 21.0003C38.0625 25.5585 36.2878 29.8435 33.0653 33.0659C29.8428 36.2882 25.5579 38.0628 21 38.0628C16.7951 38.0628 12.7555 36.52 9.62535 33.7187C9.3553 33.4771 8.94039 33.5001 8.69872 33.7701C8.45706 34.0401 8.48003 34.4551 8.75007 34.6967C12.1212 37.7138 16.4717 39.3753 21 39.3753C25.9086 39.3753 30.523 37.4642 33.9933 33.994C37.4638 30.5237 39.375 25.909 39.375 21.0003C39.375 16.7976 37.9195 12.689 35.2766 9.43134Z"
        fill="#4D1384"
      />
      <path
        d="M3.9375 21C3.9375 11.5918 11.5918 3.9375 21 3.9375C25.2049 3.9375 29.2445 5.48034 32.3746 8.28179C32.6447 8.52362 33.0597 8.50049 33.3012 8.23052C33.543 7.96048 33.52 7.54556 33.25 7.3039C29.8788 4.28662 25.5284 2.625 21 2.625C16.0922 2.625 11.4778 4.53641 8.00715 8.00715C4.53641 11.4779 2.625 16.0922 2.625 21C2.625 25.2032 4.08056 29.3121 6.72353 32.5695C6.85322 32.7294 7.04255 32.8123 7.23352 32.8123C7.37879 32.8123 7.52497 32.7643 7.64663 32.6657C7.92807 32.4373 7.97114 32.024 7.74277 31.7426C5.28888 28.7182 3.9375 24.903 3.9375 21Z"
        fill="#4D1384"
      />
      <path
        d="M29.7742 13.0962C29.5165 12.781 29.1304 12.6074 28.6871 12.6074C28.4461 12.6074 28.1908 12.6581 27.9284 12.7584L10.0677 19.5764C9.1198 19.9381 8.99215 20.481 9.00036 20.7723C9.00857 21.0637 9.16681 21.5985 10.1337 21.9061C10.1395 21.9079 10.1452 21.9097 10.151 21.9113L13.8558 22.9715L15.8594 28.7008C16.1326 29.4819 16.7458 29.967 17.4599 29.967C17.9101 29.967 18.353 29.7784 18.7406 29.4216L21.0322 27.3117L24.3559 29.988C24.3563 29.9883 24.3567 29.9885 24.3571 29.9888L24.3886 30.0142C24.3915 30.0165 24.3946 30.0189 24.3975 30.0211C24.7669 30.3077 25.1702 30.459 25.5641 30.4592H25.5643C26.3339 30.4592 26.9468 29.8895 27.1253 29.0079L30.0517 14.5577C30.1693 13.9777 30.0707 13.4587 29.7742 13.0962ZM15.0952 22.7701L22.2429 19.1186L17.7923 23.8477C17.7194 23.9251 17.6677 24.02 17.6423 24.1231L16.7841 27.5993L15.0952 22.7701ZM17.903 28.5121C17.8734 28.5393 17.8436 28.5636 17.8139 28.586L18.6101 25.3612L20.0584 26.5275L17.903 28.5121ZM28.84 14.3122L25.9136 28.7626C25.8854 28.9009 25.7954 29.2227 25.5641 29.2227C25.4498 29.2227 25.3062 29.1604 25.1592 29.0472L21.3929 26.0147C21.3925 26.0142 21.3918 26.0137 21.3912 26.0134L19.1502 24.2088L25.5863 17.3699C25.7924 17.1509 25.811 16.8156 25.6306 16.5751C25.45 16.3346 25.1227 16.2589 24.855 16.3958L14.2692 21.8037L10.5141 20.7294L28.3693 13.9135C28.5202 13.8558 28.6255 13.8437 28.6871 13.8437C28.7249 13.8437 28.7922 13.8483 28.8172 13.879C28.85 13.9191 28.8919 14.0558 28.84 14.3122Z"
        fill="#4D1384"
      />
    </svg>
  )
}

export default Telegram
