import React, { useState } from 'react'
// import { Link as Scroll } from 'react-scroll'
import { Link, useHistory, useLocation } from 'react-router-dom'
// import { HashLink } from 'react-router-hash-link'
import line from './../image/Vector 1.png'
import responsiveicon from './../image/List.png'
import close from './../image/X.png'
import vector17 from './../image/Vector 17.png'
import Ellipse9 from './../image/Ellipse 9.png'
import Ellipse33 from './../image/Ellipse 33.png'
import './Header.scss'

const Header = () => {
  const history = useHistory()

  // const mainHistory = () => {
  //   history.push('/main')
  // }

  // const companyAboutHistory = () => {
  //   history.push('/boshSahifa/company')
  // }

  // const toCompany = () => {
  //   history.push('/')
  //   closePopover()
  // }

  const [res, setRes] = useState(false)

  // const [popover, setPopover] = useState(false)
  const Vector = useLocation()
  console.log(Vector)

  const [vector, setvector] = useState('boshSahifa')

  // const menuMedia = () => {
  //   setPopover(true)
  //   setvector('media')
  // }

  const closePopover = () => {
    // setPopover(false)
  }

  const openDrawer = () => {
    setRes(true)
    document.body.style.overflow = 'hidden'
  }

  const closeDrawer = () => {
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  const kompanyFunction = () => {
    setvector('kompaniya')
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  const shoppingFunction = () => {
    setvector('xaridQilish')
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  const productFunction = () => {
    setvector('mahsulotlar')
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  const newsFunction = () => {
    setvector('yangiliklar')
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  // const articlesFunction = () => {
  //   setvector('maqolalar')
  //   setRes(false)
  //   document.body.style.overflow = 'auto'
  // }
  const contactFunction = () => {
    setvector('boglanish')
    setRes(false)
    document.body.style.overflow = 'auto'
  }
  const mainFunction = () => {
    setvector('boshSahifa')
    setRes(false)
    document.body.style.overflow = 'auto'
  }

  window.onscroll = function () {
    scrollFunction()
    // buttonScroll()
  }
  function scrollFunction() {
    const header = document.getElementById('headerMenu')
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 40) {
      header.style.boxShadow =
        '0px -7px 60px rgba(0, 0, 0, 0.08), 0px -1.56354px 13.4018px rgba(0, 0, 0, 0.0522314), 0px -0.465507px 3.99006px rgba(253, 55, 55, 0.066)'
      header.style.backgroundColor = 'white'
    } else {
      header.style.boxShadow = 'none'
      header.style.backgroundColor = 'transparent'
    }
  }

  return (
    <>
      <div className="header">
        <nav className="header_menu" id="headerMenu">
          <ul className="menuleft">
            <li onclick={() => setvector('boshSahifa')}>
              {/* <Scroll to="company" smooth={true} offset={-50} duration={400}> */}
              {/* <Link to="/" onClick={() => setvector('boshSahifa') closePopover}> */}
              <a
                // to="#boshsahifa"
                href="/bosh-Sahifa#1"
                // onClick={mainHistory}
                style={
                  Vector.hash === '#1' ? { color: '#6D35A2' } : { color: '#9D9D9D' }
                }
              >
                BOSH SAHIFA
              </a>
              {/* </Scroll> */}
              <img
                src={line}
                alt="line"
                style={
                  Vector.hash === '#1' ? { display: 'block' } : { display: 'none' }
                }
              />
            </li>
            <li>
              {/* <Scroll
                onClick={() => {
                  toCompany()
                  setvector('kompaniya')
                }}
                // to="companyAbout"
                isDynamic={true}
                offset={-50}
                smooth={true}
                duration={400}
              > */}
              <a
                href="/bosh-Sahifa#kompaniya-haqida"
                // onClick={companyAboutHistory}
                style={
                  Vector.hash === '#kompaniya-haqida'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                KOMPANIYA
              </a>
              {/* </Scroll> */}
              <img
                src={line}
                alt="line"
                style={
                  Vector.hash === '#kompaniya-haqida'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
            <li>
              {/* <Scroll
                to="shoping"
                offset={-90}
                smooth={true}
                duration={400}
                onClick={(closePopover, toCompany, () => setvector('xaridQilish'))}
              > */}
              <a
                href="/bosh-Sahifa#xarid-qilish"
                onclick={() => setvector('xaridQilish')}
                style={
                  Vector.hash === '#xarid-qilish'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                XARID QILISH
              </a>
              {/* </Scroll> */}
              <img
                src={line}
                alt="line"
                style={
                  Vector.hash === '#xarid-qilish'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
          </ul>
          <Link to="/" className="shifobaxshLink">
            <div className="shifobaxshSut">
              <h3>SHIFOBAXSH-SUT</h3>
            </div>
          </Link>
          <ul className="menuright">
            <li>
              <Link
                to="/mahsulotlar"
                onClick={(closePopover, () => setvector('mahsulotlar'))}
                style={
                  Vector.pathname === '/mahsulotlar'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                MAHSULOTLAR
              </Link>
              <img
                src={line}
                alt="line"
                style={
                  Vector.pathname === '/mahsulotlar'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
            {/* <li className="responsiveMedia">
              <Link
                onClick={menuMedia}
                style={
                  vector === 'Media' ? { color: '#6D35A2' } : { color: '#9D9D9D' }
                }
              >
                MEDIA
              </Link>
              <img
                src={line}
                alt="line"
                style={
                  vector === 'media' ? { display: 'block' } : { display: 'none' }
                }
              />
              <div
                className="media"
                style={
                  popover
                    ? { display: 'block', transition: '2s' }
                    : { display: 'none', transition: '.5s' }
                }
              >
                <Link to="/yangiliklar" onClick={closePopover}>
                  Yangiliklar
                </Link>
                <Link to="/maqolalar" onClick={closePopover}>
                  Maqolalar
                </Link>
              </div>
            </li> */}
            <li>
              <Link
                to="/yangiliklar"
                onClick={(closePopover, () => setvector('yangiliklar'))}
                style={
                  Vector.pathname === '/yangiliklar'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                YANGILIKLAR
              </Link>
              <img
                src={line}
                alt="line"
                style={
                  Vector.pathname === '/yangiliklar'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
            <li className="responsiveNews">
              <a to="/yangiliklar" onClick={closePopover}>
                YANGILIKLAR
              </a>
            </li>
            {/* <li className="responsiveMaqolalar">
              <Link to="/maqolalar" onClick={closePopover}>
                MAQOLALAR
              </Link>
            </li> */}

            <li>
              {/* <Scroll
                to="contact"
                offset={-80}
                smooth={true}
                duration={400}
                onClick={(closePopover, toCompany, () => setvector('boglanish'))}
              > */}
              <a
                href="/bosh-Sahifa#bog'lanish"
                onclick={() => setvector('boglanish')}
                style={
                  Vector.hash === "#bog'lanish"
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                BOG'LANISH
              </a>
              {/* </Scroll> */}
              <img
                src={line}
                alt="line"
                style={
                  Vector.hash === "#bog'lanish"
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
          </ul>
        </nav>
        {/* <Drawer open={openDrawer} width="100%" height="100vh">
        <h1 onClick={() => setOpenDrawer(false)}>X</h1>
      </Drawer> */}
      </div>

      {/* responsiveMobile */}

      <div className="headerMobile">
        {!res && (
          <div className="responsiveIcon" onClick={openDrawer}>
            <img src={responsiveicon} alt="iconimage" />
          </div>
        )}
        <nav
          className="headerMobile_menu"
          style={
            res
              ? { transform: 'translateX(0%)' }
              : { transform: 'translateX(-200%)' }
          }
        >
          <div className="close">
            <img src={close} alt="X" onClick={closeDrawer} />
          </div>
          <img src={Ellipse9} alt="Ellipse9" className="Ellipse9" />
          <img src={Ellipse33} alt="Ellipse9" className="Ellipse33" />
          <ul className="menuAll">
            <li onclick={() => setvector('boshSahifa')}>
              {/* <Link to="/" onClick={() => setvector('boshSahifa') closePopover}> */}
              <a
                href="/bosh-Sahifa#1"
                onClick={mainFunction}
                style={
                  Vector.hash === '#1' ? { color: '#6D35A2' } : { color: '#9D9D9D' }
                }
              >
                BOSH SAHIFA
              </a>
              <img
                src={vector17}
                alt="line"
                style={
                  Vector.hash === '#1' ? { display: 'block' } : { display: 'none' }
                }
              />
            </li>
            <li>
              <a
                href="/bosh-Sahifa#kompaniya-haqida"
                onClick={
                  kompanyFunction
                  // setvector('kompaniya')
                  // setRes(false)
                }
                style={
                  Vector.hash === '#kompaniya-haqida'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                KOMPANIYA
              </a>
              <img
                src={vector17}
                alt="line"
                style={
                  Vector.hash === '#kompaniya-haqida'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
            <li>
              <a
                href="/bosh-Sahifa#xarid-qilish"
                onClick={shoppingFunction}
                style={
                  Vector.hash === '#xarid-qilish'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                XARID QILISH
              </a>

              <img
                src={vector17}
                alt="line"
                style={
                  Vector.hash === '#xarid-qilish'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>

            <li>
              <Link
                to="/mahsulotlar"
                onClick={productFunction}
                style={
                  Vector.pathname === '/mahsulotlar'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                MAHSULOTLAR
              </Link>
              <img
                src={vector17}
                alt="line"
                style={
                  Vector.pathname === '/mahsulotlar'
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>

            <li className="responsiveNews">
              <Link
                to="/yangiliklar"
                onClick={newsFunction}
                style={
                  Vector.pathname === '/yangiliklar'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                YANGILIKLAR
              </Link>
            </li>
            {/* <li className="responsiveMaqolalar">
              <Link
                to="/maqolalar"
                onClick={articlesFunction}
                style={
                  vector === 'maqolalar'
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                MAQOLALAR
              </Link>
            </li> */}

            <li>
              {/* <Scroll
                to="contact"
                offset={0}
                smooth={true}
                duration={1000}
                onClick={(closePopover, toCompany, () => setvector('boglanish'))}
              > */}
              <a
                href="/bosh-Sahifa#bog'lanish"
                onClick={contactFunction}
                style={
                  Vector.hash === "#bog'lanish"
                    ? { color: '#6D35A2' }
                    : { color: '#9D9D9D' }
                }
              >
                BOG'LANISH
              </a>
              {/* </Scroll> */}
              <img
                src={vector17}
                alt="line"
                style={
                  Vector.hash === "#bog'lanish"
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              />
            </li>
          </ul>
          <p>&copy; 2021 Shifobaxsh</p>
        </nav>
        {/* <Drawer open={openDrawer} width="100%" height="100vh">
        <h1 onClick={() => setOpenDrawer(false)}>X</h1>
      </Drawer> */}
      </div>
    </>
  )
}

export default Header
