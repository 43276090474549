import React from 'react'

const Facebook = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="white" />
      <path
        d="M18.2843 31.8281H22.5081C22.8705 31.8281 23.1644 31.5343 23.1644 31.1719V21.6639H25.3588C25.6952 21.6639 25.9771 21.4095 26.0116 21.0749L26.3815 17.4842C26.4006 17.2995 26.3404 17.1152 26.2159 16.9774C26.0915 16.8394 25.9145 16.7607 25.7287 16.7607H23.1644V15.3063C23.1644 15.0059 23.2594 14.9834 23.4046 14.9834H25.6753C26.0378 14.9834 26.3316 14.6896 26.3316 14.3272V10.8408C26.3316 10.4794 26.0394 10.1861 25.678 10.1846L22.5463 10.1719C20.3518 10.1719 19.1806 11.1374 18.5846 11.9473C17.7523 13.0782 17.628 14.4034 17.628 15.0926V16.7606H16.2754C15.913 16.7606 15.6191 17.0545 15.6191 17.4169V21.0075C15.6191 21.37 15.913 21.6638 16.2754 21.6638H17.628V31.1719C17.628 31.5343 17.9218 31.8281 18.2843 31.8281ZM16.9316 20.3514V18.0732H18.2842C18.6466 18.0732 18.9404 17.7794 18.9404 17.417V15.0927C18.9404 14.6674 19.0088 13.5853 19.6415 12.7253C20.2474 11.9019 21.2247 11.4845 22.5435 11.4845L25.0189 11.4945V13.671H23.4044C22.6316 13.671 21.8517 14.1766 21.8517 15.3064V17.417C21.8517 17.7795 22.1455 18.0733 22.508 18.0733H25.0013L24.7665 20.3515H22.508C22.1455 20.3515 21.8517 20.6453 21.8517 21.0077V30.5156H18.9403V21.0076C18.9403 20.6452 18.6465 20.3514 18.2841 20.3514H16.9316Z"
        fill="#4D1384"
      />
      <path
        d="M35.8495 6.15103C31.8831 2.18449 26.6094 0 21 0C15.391 0 10.1176 2.18449 6.15103 6.15103C2.18449 10.1176 0 15.391 0 21C0 26.61 2.18449 31.8837 6.15103 35.85C10.1173 39.8159 15.3908 42 21 42C26.6097 42 31.8833 39.8159 35.8494 35.85C39.8157 31.8838 42 26.61 42 21C42 15.3909 39.8157 10.1174 35.8495 6.15103ZM34.9214 34.9219C31.2031 38.6399 26.2591 40.6875 21 40.6875C15.7413 40.6875 10.7974 38.6399 7.07905 34.9219C3.36041 31.2035 1.3125 26.2593 1.3125 21C1.3125 15.7416 3.36041 10.7978 7.07905 7.07913C10.7978 3.36041 15.7416 1.3125 21 1.3125C26.2589 1.3125 31.2029 3.36041 34.9214 7.07905C38.6398 10.7976 40.6875 15.7415 40.6875 21C40.6875 26.2594 38.6398 31.2036 34.9214 34.9219Z"
        fill="#4D1384"
      />
      <path
        d="M35.2766 9.43134C35.0482 9.14981 34.635 9.10675 34.3535 9.3352C34.0721 9.56358 34.029 9.97685 34.2574 10.2582C36.7112 13.2827 38.0625 17.0977 38.0625 21.0003C38.0625 25.5585 36.2878 29.8435 33.0653 33.0659C29.8428 36.2882 25.5579 38.0628 21 38.0628C16.7951 38.0628 12.7555 36.52 9.62535 33.7187C9.3553 33.4771 8.94039 33.5001 8.69872 33.7701C8.45706 34.0401 8.48003 34.4551 8.75007 34.6967C12.1212 37.7138 16.4717 39.3753 21 39.3753C25.9085 39.3753 30.523 37.4642 33.9933 33.994C37.4638 30.5236 39.375 25.909 39.375 21.0003C39.375 16.7975 37.9195 12.689 35.2766 9.43134Z"
        fill="#4D1384"
      />
      <path
        d="M3.9375 21C3.9375 11.5918 11.5918 3.9375 21 3.9375C25.2049 3.9375 29.2445 5.48034 32.3746 8.28179C32.6447 8.52362 33.0597 8.50049 33.3012 8.23052C33.543 7.96048 33.52 7.54556 33.25 7.3039C29.8788 4.28663 25.5284 2.625 21 2.625C16.0922 2.625 11.4779 4.53641 8.00715 8.00715C4.53641 11.4778 2.625 16.0922 2.625 21C2.625 25.2033 4.08056 29.3121 6.72353 32.5695C6.85322 32.7294 7.04255 32.8123 7.23352 32.8123C7.37879 32.8123 7.52497 32.7643 7.64663 32.6657C7.92807 32.4373 7.97114 32.024 7.74277 31.7426C5.28888 28.7182 3.9375 24.903 3.9375 21Z"
        fill="#4D1384"
      />
    </svg>
  )
}

export default Facebook
