import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom'
import ScrollToTop from 'react-scroll-to-top'
import './Main.scss'
import Company from '../company/Company'
import CompanyAbout from '../companyAbout/CompanyAbout'
import Contact from '../contact/Contact'
// import Product from '../product/Product'
import Shoping from '../shoping/Shoping'
// import ScrollToTop from 'react-scroll-up'
// import goUp from '../image/go up.png'
import GoUp from './../../svg/Go up'

const Main = () => {
  // const location = useLocation().pathname
  // console.log(location)

  // useEffect(() => {
  //   if (location === '/main#companyAbout') {
  //     window.scrollTo(0, 1000)
  //     console.log('working')
  //   } else {
  //     window.scrollTo(0, 0)
  //   }
  // }, [location])

  const [scrollToTop, setScrollToTop] = useState(false)

  // window.onscroll = function () {
  //   buttonScroll()
  // }
  // function buttonScroll() {
  //   const button = document.getElementById('buttonToTop')
  //   if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 40) {
  //     button.style.transform = 'scale(1)'
  //   } else {
  //     button.style.transform = 'scale(0.2)'
  //   }
  // }

  window.addEventListener('scroll', function () {
    // console.log(window.scrollY)
    if (window.scrollY >= 2835) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)
    }
  })

  return (
    <div style={{ position: 'relative' }}>
      <Company />
      <CompanyAbout />
      <Shoping />
      <Contact />

      {/* <button
        id="buttonToTop"
        easing="linear"
        className="scrolltotop"
        onClick={() => window.scrollTo(0, 0)}
        // onClick={buttonScroll}
        style={
          !scrollToTop
            ? {
                position: 'fixed',
                bottom: '50px',
                right: '40px',
                boxShadow: '0 0 10px 10px white',
                cursor: 'pointer',
                borderRadius: '50%',
              }
            : {
                borderRadius: '50%',
                cursor: 'pointer',
                position: 'absolute',
                bottom: '50px',
                right: '40px',
                boxShadow: '0 0 10px 10px white',
              }
        }
        showUnder={600}
        duration={200}
      >
        <GoUp />
      </button> */}

      <ScrollToTop smooth />
    </div>
  )
}

export default Main
