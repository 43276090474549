import React from 'react'
import './Company.scss'
import logo from './../image/LOGO_031 1.png'
import plasticYogurt from './../image/plastic-yogurt-cup-11 1.png'
import wave from './../image/Vector 6.png'
import { useHistory } from 'react-router-dom'

const Company = () => {
  const history = useHistory()
  return (
    <div className="mainCont" id="1">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="main" id="company">
        {/* <div className="ellipse"></div> */}
        <div className="logodiv">
          <img src={plasticYogurt} className="plasticYogurt" alt="yogurt" />
        </div>

        <div className="circlesCont">
          <div className="shortText">
            <h1>
              Ekologik toza
              <br />
              sut mahsulotlari
            </h1>
            <p>
              “Shifobaxsh” sut mahsulotlari – bu tabiiy,
              <br /> foydali va eng asosiysi ekologik toza
              <br /> mahsulotlardir!
            </p>
          </div>
          <div className="shortTextResponsive">
            <h1>
              Ekologik toza sut
              <br />
              mahsulotlari
            </h1>
            <p>
              “Shifobaxsh” sut mahsulotlari –<br /> bu tabiiy, foydali va eng
              asosiysi
              <br /> ekologik toza mahsulotlardir!
            </p>
          </div>
          <div className="circleAllDiv">
            <div className="circle">
              <div
                className="circleButton"
                onClick={() => history.push('/mahsulotlar')}
              >
                <p>Mahsulotlar</p>
              </div>
              <div className="circleAnimation">
                <div className="circleMini"></div>
              </div>
            </div>
          </div>
          <div className="ellipse">
            <div className="ellipseIn"></div>
          </div>
          <div className="ellipseMini"></div>
          <div className="ellipseMini1"></div>
          <img src={wave} alt="wave" className="wave" />
        </div>
      </div>
    </div>
  )
}

export default Company
