import React from 'react'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
// import { animateScroll as scroll } from 'react-scroll'
import { gsap } from 'gsap'
import ScrollIntoView from './ScrollIntoView'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ProductComponent from './components/productComponent/ProductComponent'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.scss'
import Main from './components/main/Main'
import News from './components/news/News'
import FullCard from './components/news/fullCard/FullCard'

import ReactNotifications from 'react-notifications-component'
// import ScrollToTop from 'react-scroll-up'
// import Company from './components/company/Company'
// import CompanyAbout from './components/companyAbout/CompanyAbout'
// import Shoping from './components/shoping/Shoping'

gsap.registerPlugin(ScrollTrigger)
window.addEventListener('scroll', function () {
  // console.log(window.scrollY)
})
function App() {
  // const scrollToTop = () => {
  //   scroll.scrollToTop()
  // }

  // console.log(window.pageYOffset)
  // window.addEventListener('scroll', function () {
  //   console.log(window)
  //   if (window.scrollY >= 2778) {
  //     document.querySelector('.scrolltotop').style.bottom = '50px'
  //   } else {
  //     document.querySelector('.scrolltotop').style.bottom = '0'
  //   }
  // })

  // useEffect(() => {
  //   gsap.to('.scrolltotop', {
  //     duration: 1,
  //     bottom: 50,
  //     ease: 'bounce',

  //     scrollTrigger: {
  //       trigger: document.body,
  //       scrub: 1,
  //       markers: false,
  //       start: 'bottom bottom',
  //       end: 'bottom bottom',
  //     },
  //   })
  // })

  return (
    <div className="App">
      {/* <div className="App_top">
        <h1 className="App_top_title">SHIFOBAXSH</h1>
      </div> */}
      <ReactNotifications />
      <BrowserRouter>
        <ScrollIntoView>
          <Header />
          <Switch>
            <Route path="/" exact component={Main} />

            <Route path="/bosh-Sahifa" component={Main} />
            {/* <Route path="/company" component={CompanyAbout} /> */}
            <Route path="/mahsulotlar" component={ProductComponent} />
            <Route exact path="/yangiliklar" component={News} />
            <Route path="/yangiliklar/:id" component={FullCard} />
          </Switch>
          <Footer className="footer" />
        </ScrollIntoView>
      </BrowserRouter>
      {/* <span
        id="myBtn"
        className="scrolltotop"
        onClick={scrollToTop}
        style={{
          display: document.documentElement.scrollToTop === 0 ? 'none' : 'block',
        }}
      >
        <img src={goUp} alt="go up" />
      </span> */}

      {/* <ScrollToTop showUnder={600} duration={800}>
        <span className="scrolltotop">
          <img src={goUp} alt="go up" />
        </span>
      </ScrollToTop> */}
    </div>
  )
}

export default App
